import { useState, useEffect } from "react";

const secondarySponsorImages = [
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_forum-berufsbildung.png",
    sponsorUrl: "https://www.forum-berufsbildungzo.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_bgv-uster.png",
    sponsorUrl: "https://www.bgu-net.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_bgv-hinwil.png",
    sponsorUrl: "https://www.bgv-hinwil.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_berufsschule-rueti.png",
    sponsorUrl: "https://www.bsrueti.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_gewerbliche-berufsschule-wetzikon.png",
    sponsorUrl: "https://www.gbwetzikon.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor-bildungszentrum-uster.png",
    sponsorUrl: "https://www.bfsu.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_bgv-pfaeffikon-zh.png",
    sponsorUrl: "https://www.bgv-pfaeffikon.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_gewerbeverein-pfaeffikon-zh.png",
    sponsorUrl: "https://perle-am-see.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_pfaeffikon-zh.png",
    sponsorUrl: "https://www.pfaeffikon.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_bank-avera.png",
    sponsorUrl: "https://www.bank-avera.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_avzo.png",
    sponsorUrl: "https://avzo.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_hubersuhner.png",
    sponsorUrl: "https://www.hubersuhner.com/de",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_ferag.png",
    sponsorUrl: "https://www.ferag.com/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_hostpoint.png",
    sponsorUrl: "https://www.hostpoint.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_mock.png",
    sponsorUrl: "https://www.mockschriften.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_ehm.png",
    sponsorUrl: "https://ehm.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_hustech.png",
    sponsorUrl: "https://www.hustech.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_swissmem.png",
    sponsorUrl: "https://www.swissmem.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_neukomoptik.png",
    sponsorUrl: "https://neukom-optik.ch/",
  },
  {
    imageSrc:
      "https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_crealine.png",
    sponsorUrl: "https://crealine.net/",
  },
];

const Sponsor = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % secondarySponsorImages.length
      );
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="py-4">
      <div className="container d-flex justify-content-around flex-nowrap">
        <a
          href="http://www.gebauerstiftung.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_gebauer-stiftung.png"
            alt="Sponsor Gebauer Stiftung"
          />
        </a>
        <a
          href="http://www.zh.ch/berufsbildungsfonds"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_kanton-zuerich-bildungsdirektion.png"
            alt="Sponsor Kanton Zürich Bildungsdirektion"
          />
        </a>
        <a
          href="https://www.kgv.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_kgv.png"
            alt="Sponsor KGV"
          />
        </a>
        <a
          href="https://www.zuerioberland-wirtschaft.ch/wirtschaftsregion/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_zuerioberland.png"
            alt="Sponsor Zürioberland Wirtschaft"
          />
        </a>
        <a
          href="http://zueriost.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_zueriost.png"
            alt="Sponsor Züriost"
          />
        </a>
      </div>
      <div className="container d-flex justify-content-center mt-4">
        <a
          href={secondarySponsorImages[currentIndex].sponsorUrl}
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src={secondarySponsorImages[currentIndex].imageSrc}
            alt={`Rotating Sponsor ${currentIndex + 1}`}
          />
        </a>
      </div>
    </div>
  );
};

export default Sponsor;
